import { Router } from 'react-router-dom';
import { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// material
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { UseWalletProvider } from 'use-wallet';

// routes
import routes, { renderRoutes } from './routes';
// redux
import { persistor } from './redux/store';
// theme
import ThemeConfig from './theme';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';

// Using for Auth (Check doc https://minimals.cc/docs/authentication)
import JwtProvider from './components/authentication/JwtProvider';

import { getChainID } from './redux/slices/user';

// ----------------------------------------------------------------------

const history = createBrowserHistory();

export default function App() {
  const dispatch = useDispatch();
  const { chainID } = useSelector((state) => state.user);

  const RPCURLs = {
    56: 'https://bsc-dataseed.binance.org',
    97: 'https://bsc-testnet.public.blastapi.io',
    5: 'https://mainnet.infura.io/v3/04717abe3f0f4e7686814322c2f391cd',
    1: 'https://mainnet.infura.io/v3/04717abe3f0f4e7686814322c2f391cd',
    770077: 'https://mainnet.grovechain.io'
  };

  console.log('chainID', chainID);

  useEffect(() => {
    dispatch(getChainID('grove'));
  }, [dispatch]);

  return (
    <HelmetProvider>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <ThemeConfig>
          <RtlLayout>
            <UseWalletProvider
              chainId={chainID}
              connectors={{
                walletconnect: {
                  rpcUrl: RPCURLs[chainID]
                }
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <NotistackProvider>
                  <Router history={history}>
                    <JwtProvider>
                      <ScrollToTop />
                      <GoogleAnalytics />
                      {renderRoutes(routes)}
                    </JwtProvider>
                  </Router>
                </NotistackProvider>
              </LocalizationProvider>
            </UseWalletProvider>
          </RtlLayout>
        </ThemeConfig>
      </PersistGate>
    </HelmetProvider>
  );
}
